<template>
  <div class="main">
    <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="getList"></custem-table>
  </div>
</template>

<script setup>

import { userManagement } from '@/utils/api'
import { onMounted, reactive, inject, ref } from 'vue'
import { ElMessage } from "element-plus"

const t = inject('t')
const tableLoading = ref(false)

// 删除
const deleteData = async (row) => {
  const { id } = row
  if (!!!id) return
  await userManagement.userOauthDelete(id)
  ElMessage.success(t('table.del_success'))
  tableInfo.data = tableInfo.data.filter((ele) => ele.id !== id)
}

// 表格信息
const tableInfo = reactive({
  selection: false, // 选择框列
  header: [
    { // 用户名
      key: 'name',
      label: t('form.username'),
      width: '320px',
      custem: (data) => {
        return data.user && data.user.name ? data.user.name : '-'
      }
    },
    { // 三方平台
      key: 'oauth',
      label: t('user.third_party'),
      width: '140px',
      sortable: true,
      sortBy: (data) => {
        return data.oauth
      }
    },
    { // 联合编号
      key: 'union_id',
      label: t('user.union_id'),
      minWidth: '320px'
    },
    { // 三方编号
      key: 'third_id',
      label: t('user.third_id'),
      minWidth: '320px'
    },
    {
      key: 'login_at',
      label: t('table.action'),
      width: '90px',
      fixed: 'right',
      handleFun: [
        {
          name: t('form.delete'),
          type: 'delete',
          content: t('form.sure_del'),
          fn: deleteData
        },
      ]
    }
  ]
})

const getList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await userManagement.userOauth({
      ...search
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>